import {TFunction} from 'i18next';
import React from 'react';
import {convertWattsToKw} from '@/Areas/Connector/Helpers/ConnectorHelpers';

const WattToKw = (props: WattToKwProps): React.JSX.Element => {
    const {t, amount, perHour = true} = props;

    return <>{convertWattsToKw(amount, t, perHour)}</>;
};

export type WattToKwProps = {
    t: TFunction<any>;
    amount: number;
    perHour?: boolean;
};

export default WattToKw;
