import {BooleanValidator, ObjectValidator, StringValidator} from 'formifly';
import {TFunction} from 'i18next';
import punycode from 'punycode/punycode';
import {PunycodeEmailValidator} from '@common/butterfly-shared-react-library';
import {globalConfig} from '@/Helpers/globalConfig';

export const getLoginShape = (t: TFunction<any>): ObjectValidator<any> => {
    return new ObjectValidator({
        email: new PunycodeEmailValidator().required(t('error.email_required')),
        password: new StringValidator().required(t('error.password_required')),
        stay_logged_in: new BooleanValidator(),
    });
};

export const makeLoginRequest = async (email: string, password: string, stayLoggedIn: boolean): Promise<any> => {
    const emailRegexp = /.+@.+/;
    if (emailRegexp.test(email)) {
        email = punycode.toASCII(email);
    }
    const response = await fetch(globalConfig.customerApiUrl as string + '/auth', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email,
            password: password,
            stay_logged_in: stayLoggedIn,
        }),
    });
    if (response.ok) {
        return response.json();
    } else {
        return Promise.reject(await response.json());
    }
};

//export const getPasswordChangeShape = (t: TFunction) => {
//   return new ObjectValidator({
//        password: new StringValidator().required(),
//        password_repeat: new PasswordRepeatValidator('password').required(),
//    })
//}

