import {Dispatch, SetStateAction} from 'react';
import {TFunction} from 'i18next';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {ApiResponse} from '@/Types/Api';
import {ChargeLocation, SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {globalConfig} from '@/Helpers/globalConfig';
import {ConnectorError, PaymentError, PricegroupError} from '@/Areas/Connector/Data/ConnectorTypes';

export const fetchConnector = (
    uid: string,
    setConnector: Dispatch<SetStateAction<SimplifiedConnectorWithLocationInfo | undefined>>,
    setError: Dispatch<SetStateAction<ConnectorError | PricegroupError | PaymentError | undefined>>,
): Promise<void> => {
    //Convert to uppercase in case it was provided otherwise. EVSE IDs are case insensitive by definition.
    uid = uid.toUpperCase();
    return fetchWithRetry<ApiResponse<ChargeLocation>>(
        (globalConfig.customerApiUrl as string) + '/charge-locations?connector_uid=' + uid,
        withCredentialsAndContentType({method: 'GET'}),
    )
        .then((locationResult: ApiResponse<ChargeLocation>) => {
            if (locationResult.total_count !== 1) {
                setError(ConnectorError.NOT_FOUND);
                return;
            }

            const fetchedLocation = locationResult.items[0];
            const stationIndex = fetchedLocation.stations.findIndex(fetchedStation => {
                return (
                    fetchedStation.connectors.findIndex(fetchedConnector => {
                        if (fetchedConnector.uid === uid) {
                            setConnector({
                                ...fetchedConnector,
                                ...fetchedLocation,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    }) !== -1
                );
            });

            if (stationIndex === -1) {
                setError(PricegroupError.NOT_AVAILABLE);
                return;
            }
        })
        .catch((reason: Error) => {
            console.error('Location fetch failed', reason);
            setError(ConnectorError.NETWORK_ERROR);
        });
};

export const convertWattsToKw = (amount: number, t: TFunction<['common']>, perHour?: boolean): string => {
    if (perHour === undefined || perHour) {
        return String(amount / 1000).replace('.', t('common:unit.currency.decimal_separator')) + ' ' + t('common:unit.kWh');
    } else {
        return String(amount / 1000).replace('.', t('common:unit.currency.decimal_separator')) + ' ' + t('common:unit.kW');
    }
};
