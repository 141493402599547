import React from 'react';
import styled from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormiflyForm} from 'formifly';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {Alert, AlertColor, Button, CardContent, Grid} from '@mui/material';
import AuthContainer, {AuthContainerHeader} from '@/Areas/Auth/Components/AuthContainer';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';
import {getLoginShape, makeLoginRequest} from '@/Areas/Auth/Data/Auth';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import {setItem} from '@/Helpers/localStorageHelpers';


type loginFormSubmitType = {
    email: string;
    password: string;
    stay_logged_in: string | boolean;
};

const Auth = (): React.JSX.Element => {
    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    const {fetchAndSetUserData, setLoggedInState} = useUserContext();

    const {state} = useLocation();
    const navigate = useNavigate();

    const {t} = useTranslation(['auth', 'common', 'formifly']);

    const shape = getLoginShape(t);

    const handleSubmit = (values: loginFormSubmitType): void => {
        makeLoginRequest(values.email, values.password, Boolean(values.stay_logged_in))
            .then((result) => {
                if (!result.access_token) {
                    return Promise.reject();
                } else {
                    setItem('jwt', result.access_token);
                    setItem('stay_logged_in', values.stay_logged_in);

                    return fetchAndSetUserData().then((fetchSuccess) => {
                        if (!fetchSuccess) {
                            return Promise.reject();
                        }

                        setLoggedInState(LoggedInState.LoggedIn);

                        if (state !== null) {
                            if (state.redirected_from && state.redirected_from !== '') {
                                navigate(state.redirected_from);
                            } else {
                                navigate('/');
                            }
                        } else {
                            navigate('/');
                        }

                        return Promise.resolve();
                    });

                }
            })
            .catch((reason) => {
                if (reason?.error?.code === 'auth_failed') {
                    setAlert({show: true, message: 'error.invalid_credentials', severity: 'error'});
                } else {
                    console.error('Login failed: ', reason);
                    setAlert({show: true, message: 'error.unexpected_error', severity: 'error'});
                }
            });
    };

    return <AuthContainer>
        <AuthContainerHeader title={t('login')}/>
        <CardContent>
            <Grid container>
                {alert.show && <PaddedGrid item xs={12}>
                    <Alert severity={alert.severity as AlertColor}>
                        <Trans t={t} ns="auth">{alert.message}</Trans>
                    </Alert>
                </PaddedGrid>}
                <FormiflyForm onSubmit={handleSubmit as any} shape={shape} t={t as any}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="email"
                                              label={t('email')}
                                              autoFocus={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInputField name="password" label={t('password')} t={t}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="stay_logged_in" label={t('stay_logged_in')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" type="submit" variant="contained" fullWidth={true}>
                                {t('login')}
                            </Button>
                        </Grid>
                    </Grid>
                </FormiflyForm>
            </Grid>
        </CardContent>
    </AuthContainer>;
};

const PaddedGrid = styled(Grid)`
    padding-bottom: 16px;
`;

export default Auth;
